.App {
  text-align: center;
}



.arrow-edit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.arrow-edit input {
  width: 20px;
}

.timer-edit {
  display: flex;
  flex-direction: row;
}

.back-message {
  color: red;
  background-color: pink;
  padding: 5px;
}

.duration-view {
  font-size: 5rem;
}

.expired {
	-webkit-animation: flash linear 1s infinite;
	animation: flash linear 1s infinite;
}

@-webkit-keyframes flash {
	0% { opacity: 1; } 
	50% { opacity: .1; } 
	100% { opacity: 1; }
}
@keyframes flash {
	0% { opacity: 1; } 
	50% { opacity: .1; } 
	100% { opacity: 1; }
}